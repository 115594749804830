import { graphql } from 'gatsby'
import React, { Component } from 'react'
import Layout from '../components/Layout'
import { BaseQuery,Project, ProjectQuery } from '../interface'

type ProjecProps = {
    data: BaseQuery<ProjectQuery>
}

class Projects extends Component<ProjecProps, {}> {
    componentDidMount(){
        console.log(this.props.data.swapi.project);
        
    }
  render() {
    return (
		<Layout>
             <section className="content-section-md">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <h2>What i've Done ?</h2>
                            <p>More detail visit my <a href="https://www.linkedin.com/in/mkhotib20/" target="_blank">linkedin</a></p>
                        </div>
                        {this.props.data.swapi.project.map((v,i) => {
                            return(
                                <div className="col-md-4 my-2">
                                    <ProjectBox content={v} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
		</Layout>
    )
  }
}

type ProjectBoxProps = {
    content: Project
} 

const  ProjectBox = (props: ProjectBoxProps) =>{
    return(
        <div className="project-box">
            <div style={{
                background: `url('${props.content.img.url}') center center no-repeat`,
                backgroundSize: "cover"
            }} className="pb-img"></div>
            <div className="caption text-center">
                <p>{props.content.title}</p>
            </div>
            <div className="hovering text-center">
                <small>{props.content.desctiption}</small>
                <a href={props.content.url} target="_blank" className="btn btn-main">Visit Site </a>
            </div>
        </div>
    )
}


export const query = graphql`
  {
    swapi {
      project {
        id
        title
        desctiption
        url
        img {
          url
        }
      }
    }
  }
`
export default Projects
